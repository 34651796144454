const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  SET_CLIENT: 'SET_CLIENT',
  SET_CLIENT_SUCCESS: 'SET_CLIENT_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload = false, refresh = false) => ({
    type: actions.LOGIN_REQUEST,
    payload,
    refresh
  }),
  setClient: ({clientId = false, userInfo = {}}) => ({
    type: actions.SET_CLIENT,
    payload: {clientId, userInfo},
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
