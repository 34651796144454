const localDataName = 'mateTenancies';
const table = 'tenancies';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const newItem = {
  bot_name: '',
  style:JSON.stringify({color: '#453878', borderRadius: 10}),
  user_id: userID,
  client_id: clientID,
  system_prompt: 'DUMMYSTRING',
  prompt: 'DUMMYSTRING',
  enable_domain_validation: 0,
  allowed_domains: '',
  api_key: '',
  auth_type: null,
  auth_url: '',
  auth_header: '',
  mock_learner_name: '',
  mock_learner_id: null,
  initial_message: '',
  initial_questions: [],
  fk_username: '',
  fk_password: '',
  learner_name: null,
  logo:null,
  thumbnail:null,
  previewLogo:null,
  previewThumbnail:null,
  enable_email_service: 0,
  smtp_service_host: null,
  smtp_service_port_number: 587,
  email_service_chatbot_user_id: null,
  email_service_chatbot_user_name: '',
  email_service_username: null,
  email_service_password: null,
  email_service_server: null,
  email_service_port_number: 993,
}

export {
  table,
  localDataName,
  newItem
};
