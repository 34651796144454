export const hasPermission = (permissionKey, currentUserInfo, currentClientInfo) => {
  
  return currentUserInfo.permissions.some((permissionObj) => {
    if (permissionObj?.client?.id === currentClientInfo?.id || permissionObj.role_code === 'admin' || permissionObj.role_code === 'owner') {
      return permissionObj.permissions.includes(permissionKey);
    }
    return false
  });
  }
    

export const signInPermissions = (values, currentUserInfo) => {

  const roles = currentUserInfo.permissions.map((permission) => {
    return permission.role_code;
  })

  return values.some((value) => roles.includes(value))
};

export const checkRolePermissions = (values, currentUserInfo, currentClientInfo) => {

  const roles = currentUserInfo.permissions.map((permission) => {
    if (permission?.client?.id === currentClientInfo?.id || permission.role_code === 'admin' || permission.role_code === 'owner') {
      return permission.role_code;
    }
    return null;
  });
  return values.some((value) => roles.includes(value));
};