const localDataName = 'mateLearners';
const table = 'learners';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const newItem = {
  fname: '',
  lname: '',
  preferred_name: '',
  email_address: '',
  tenancy: null,
  learnerID: '',
  tags: '',
  learners_info: null,
  enable_chat: 0,
  user_id: userID,
  client_id: clientID,
  whatsapp_number: "",
  whatsapp_number_region: ""
}

export {
  table,
  localDataName,
  newItem
};
