import actions from './actions';

const initState = { 
  idToken: null,
  profile: {},
  userInfo: {},
  clientInfo: {},
  permissions: []
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        userInfo: { ...action.userInfo },
        clientInfo: action.clientInfo
      };
    case actions.SET_CLIENT_SUCCESS:
      return {
        ...state,
        clientInfo: action.payload,
      };
    // case actions.LOGOUT:
    //   return initState;
    default:
      return state;
  }
}
