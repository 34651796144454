import actions from './actions';

const initState = [];

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESET_ITEMS: {
      return initState;
    }
    case actions.LOAD_ITEMS_SUCCESS: {
      return [
        ...action.items,
      ];
    }
    default:
      return state;
  }
}
