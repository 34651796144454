const localDataName = 'mateSubmissions';
const table = 'submissions';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const newItem = {
  learner_id: null,
  learner_name: null,
  attempt: null,
  assessment_id: null,
  learnerID: '',
  submission_file: '',
  upload_folder: '',
  version: '',
  user_id: userID,
  client_id: clientID
}

export {
  table,
  localDataName,
  newItem
};
