const localDataName = 'mateCourses';
const table = 'courses';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Code',
    index: 'code',
    width: '25%'
  },
  {
    title: 'Center',
    index: 'center',
    width: '25%'
  }
]

const newItem = {
  name: null,
  code: null,
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
