import { all, takeEvery, put, call } from 'redux-saga/effects';
import SuperFetch from '../../library/helpers/superFetch';
import notification from '@iso/components/Notification';
import actions from './actions';
import UsersActions from '../users/actions';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export function* getItem({params}) {

  yield put({
    type: actions.UPDATE_ITEM,
    loading: true
  });

  const { filters = {}, sorter, page, limit } = params || {};
  
  const filterParams = JSON.parse(JSON.stringify(filters))

  Object.keys(filters).forEach(key => {
    if (filters[key] === null) {
      delete filters[key];
    } else if (typeof(filters[key])=== 'object') {
      filters[key] = filters[key].join()
    }
  });
  
  let data = {
    page: page ? page : 1,
    perPage: limit ? limit : 10,
    search: filters,
    sort: {}
  };

  if(sorter?.column) {
    data.sort[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
  }

  try {
  const response = yield call(SuperFetch.post, 'getLearners', { 'page': data.page, ...data.search, ...data.sort })
  if(response?.success) {
    yield put({
      type: actions.UPDATE_ITEM,
      items: response.data,
      count: response.total_count,
      currentPage: { pagination : { current: data.page } },
      loading: false,
      filterParams
    });
  } else if (response?.success === false) {
    notification('error', response.message);
  }
  } catch (error) {
    console.log(error)
  }
}
export function* updateItemSaga({ item }) {
  let id = false
  if(item.id === 'new') {
    delete item.id
  } else {
    id = item.id === 'new' ? false : item.id
  }
  try {
  const response = yield call(SuperFetch.post, 'saveLearner', item)
  if(response?.success) {
    if (!id) { response.data.is_newly_saved = true }
    try {
      yield put({
        type: actions.UPDATE_ITEM,
        item: response.data,
        count: id ? undefined : '+1'
      });
      notification('success', response.message);
      yield put({
        type: actions.SELECT_CURRENT_ITEM,
        id: response.data.id
      });
    } catch (error) {
      console.log(error)
    }
  } else if (response?.success === false) {
    if (response?.data?.length) {
      response.data.forEach((e) => {
        if(e.field && e.message) notification('error', `${e.message}: ${e.field}`);
      })
    } else {
      notification('error', response.message);
    }
  }
  } catch (error) {
    console.log(error)
  }
}
export function* loadItem({ id, acEnabled }) {
  try {
  if (id !== 'new') {
    const response = yield call(SuperFetch.post, `getLearners`, { id })
    let returnItem = {};
    if(response?.success) {
      returnItem = response.data[0]
      yield put({
        type: actions.UPDATE_ITEM,
        item: returnItem,
      });
      if(acEnabled) {
        const submissionsResponse = yield call(SuperFetch.post, `getSubmissions`, { learner_id: [id], status: [3] })
        if(submissionsResponse?.success) {
          returnItem.submissionData = submissionsResponse.data
          let formattedSubmissionData = []
          if(returnItem.submissionData?.length) {
            returnItem.submissionData.forEach(submission => {
              if(!formattedSubmissionData.find(e => e.aId === submission.assessment_id)) {
                formattedSubmissionData.push({
                  aId: submission.assessment_id,
                  aName: submission.assessment_name,
                  submissions: []
                })
              } 
              formattedSubmissionData.find(e => e.aId === submission.assessment_id).submissions.push(submission)
            })
          }

          formattedSubmissionData.forEach(data => {
            data.submissions.sort((a, b) => {
                // Convert the date strings to Date objects for comparison
                const dateA = new Date(a.date_submitted);
                const dateB = new Date(b.date_submitted);
        
                // Compare the dates
                return dateA - dateB;
            });
          });

          returnItem.submissionData = formattedSubmissionData

          yield put({
            type: actions.UPDATE_ITEM,
            item: returnItem,
          });
        }
      }

    } else if (response?.success === false) {
      notification('error', response.message);
    }
  } else {
    yield put({
      type: actions.SELECT_CURRENT_ITEM_UPDATE,
      id
    });
  }
  } catch (error) {
    console.log(error)
  }
}
export function* deleteItems({ newItems, deleteItems, count }) {
  const requestsArray = []
  try {
  NProgress.start();
  deleteItems.forEach(e => {
    requestsArray.push(
      call(SuperFetch.post, `deleteLearner`, { id:e })
    )
  })
  NProgress.set(0.2);
  const responseArray =yield all(requestsArray)
  NProgress.set(0.8);
  let deletedItems = responseArray.filter(e => e.success === true).length
  let unDeletedItems = responseArray.filter(e => e.success === false).length

  if(deletedItems && unDeletedItems === 0) {
    notification('success', `${deletedItems} Learner${deletedItems === 1 ? '' : 's'} Deleted`);
    yield put({
      type: actions.UPDATE_ITEM,
      items: newItems
    });
    yield put({
      type: UsersActions.GET_ITEM
    });
  } else if (unDeletedItems) {
    notification('error', `${unDeletedItems > 1 ? unDeletedItems : ''} Learner${unDeletedItems === 1 ? '' : 's'} linked could not be deleted`);
    if(deletedItems) {
      notification('error', `Please refresh`);
    }
  }
  } catch (error) {
    console.log(error)
  }
  NProgress.done();
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_ITEM, getItem),
    yield takeEvery(actions.UPDATE_ITEM_SAGA, updateItemSaga),
    yield takeEvery(actions.SELECT_CURRENT_ITEM, loadItem),
    yield takeEvery(actions.DELETE_ITEM_SAGA, deleteItems),
  ]);
}
