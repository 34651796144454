const localDataName = 'mateCriteriasGrades';
const table = 'criteriasGrades';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Assessment Criteria',
    index: 'assessment_criteria_id',
    width: '25%'
  },
  {
    title: 'Grade Number',
    index: 'grade_number',
    width: '25%'
  }
]

const newItem = {
  name: '',
  assessment_criteria_id: null,
  grade_number: null,
  example: '',
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
