const localDataName = 'mateClients';
const table = 'clients';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const newItem = {
  name: '',
  ac_enable: 0,
  cc_enable: 0,
  json_schema: null,
  user_id: userID,
  client_id: clientID
}

export {
  table,
  localDataName,
  newItem
};
