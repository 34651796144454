import { checkRolePermissions } from '../../utils/permissions';
import { clearToken } from './utility';
import notification from '@iso/components/Notification';

const apiURL = process.env.REACT_APP_API_URL;

const jsonToFormData = (jsonObject) => {
  const formData = new FormData();
  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      formData.append(key, jsonObject[key]);
    }
  }
  return formData;
}


const base = (method, url, data = null) => {
  console.log(method, url, data)
  let body = undefined
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if((url === 'verifyOtp' && localStorage.getItem('2fa_id_token')) || localStorage.getItem('id_token')) {
    headers.Authorization = url === 'verifyOtp' ? `Bearer ${localStorage.getItem('2fa_id_token')}` : `Bearer ${localStorage.getItem('id_token')}`
  }
  const learner_id = data?.learner_id
  if(url === 'userToken') {
    delete headers['Content-Type']
    body = jsonToFormData(data)
  } else {
    if(data) {
      const clientInfo = localStorage.getItem('client_info') ? JSON.parse(localStorage.getItem('client_info')) : {}
      const userInfo = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : null;

      if(!data.order_by && !data.order_dir && ![
        'userToken',
        'verifyOtp',
        'refreshUserToken',
        'sendPasswordResetLink',
        'verifyresetlink',
        'resetPassword',
        'generateApiKey',
        'getPowerBIToken',
        'generateLearnerAccess'
      ].includes(url) && !url.startsWith('save')) {
        data.order_by = 'id'
        data.order_dir = 'desc'
      }
      if(![
        'sendPasswordResetLink',
        'verifyOtp',
        'verifyresetlink',
        'resetPassword',
        'generateApiKey',
        ...checkRolePermissions(['owner', 'admin'], userInfo, clientInfo) ? ['getUsers', 'getClients', 'saveUser'] : []
      ].includes(url)) {
        data.client_id = clientInfo.id || process.env.REACT_APP_CLIENT_ID
      }
      body = JSON.stringify(data)
    }
  }
  
  // clearLocalStorage - NEED CLEANUP
  if([
    'saveCourse',
    'deleteCourses',
    'saveTenancy',
    'deleteTenancy',
    'saveUser',
    'deleteUser',
    'generateApiKey',
    'saveClient',
    'deleteClient',
    'saveAssessment',
    'deleteAssessment',
    'saveAssessmentCriteria',
    'deleteAssessmentCriteria',
    'saveLearner',
    'deleteLearner'
  ].includes(url)) {
    const getUrl = {
      'saveCourse': 'getCourses',
      'deleteCourses': 'getCourses',
      'saveTenancy': 'getTenancies',
      'deleteTenancy': 'getTenancies',
      'saveUser': 'getUsers',
      'deleteUser': 'getUsers',
      'generateApiKey': 'getUsers',
      'saveClient': 'getClients',
      'deleteClient': 'getClients',
      'saveAssessment': 'getAssessments',
      'deleteAssessment': 'getAssessments',
      'saveAssessmentCriteria': 'getAssessmentCriterias',
      'deleteAssessmentCriteria': 'getAssessmentCriterias',
      'saveLearner': 'getLearners',
      'deleteLearner': 'getLearners'
    }
    localStorage.removeItem(`${apiURL}/${getUrl[url]}`)
  }

  return fetch(`${apiURL}/${url}`, {
    method,
    headers,
    body,
  })
    .then(async (response) => {
      if(response.status === 401 && !learner_id) {
        try {
          clearToken();
          if(!['userToken','verifyOtp'].includes(url)) {
            window.location.replace('/');
          }
        } catch (e) {
          console.log(e)
        }
      } else if(response.status === 403 && !['userToken','getPowerBIToken'].includes(url)) {
        notification('error', 'You do not have permission to perform this action')
        const { message } = await response.json();
        throw new Error(message ||  'Permission denied!');
      }
      if(['downloadBulkImportTemplate'].includes(url)) {
        return response
      }
      return response.json()
    })
    .then(res => res)
    // .catch((error) => { console.log(error)});
    // .catch(error => ({ error: 'Server Error' }));
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
