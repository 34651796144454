const localDataName = 'mateUsers';
const table = 'users';

const userID = process.env.REACT_APP_USER_ID;

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Code',
    index: 'code',
    width: '25%'
  },
  {
    title: 'Center',
    index: 'center',
    width: '25%'
  }
]

const newItem = {
  fname: '',
  lname: '',
  username: '',
  roles: [],
  status: 1,
  enable_api_key_auth: 0,
  enable_two_factor_auth: 0,
  user_id: userID,
  password: '',
  confirm_password: ''
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
