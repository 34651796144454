const localDataName = 'mateAssessmentsGrades';
const table = 'assessmentsGrades';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'Title',
    index: 'title',
    width: '25%',
    sortable: true
  },
  {
    title: 'Min Score',
    index: 'min_score',
    width: '25%',
    sortable: true
  },
  {
    title: 'Assessment',
    index: 'assessment_name',
    width: '25%',
    sortable: true
  },
  {
    title: 'Updated At',
    index: 'updated_at',
    type: 'date',
    width: '25%'
  }
]

const newItem = {
  title: '',
  assessment_id: null,
  min_score: null,
  user_id: userID,
  grade_category: null,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
