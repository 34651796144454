const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const localDataName = 'mateAssessments';
const table = 'assessments';

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Code',
    index: 'code',
    width: '25%'
  },
  {
    title: 'Status',
    index: 'status',
    width: '25%',
    type: 'status',
    filters: [
      { text: 'Draft', value: 2 },
      { text: 'Enabled', value: 1 },
      { text: 'Disabled', value: 0 },
    ]
  }
]

const newItem = {
  name: '',
  code: '',
  version: '',
  status: 1,
  max_word_count: null,
  accepted_format: 'json',
  course_id: null,
  valid_from_date: null,
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
