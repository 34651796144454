import { all, takeEvery, put, call } from 'redux-saga/effects';
import SuperFetch from '../../library/helpers/superFetch';
import notification from '@iso/components/Notification';
import actions from './actions';

export function* getItem({params}) {

  yield put({
    type: actions.UPDATE_ITEM,
    loading: true
  });

  const { filters = {}, sorter, page, limit } = params || {};
  
  const filterParams = JSON.parse(JSON.stringify(filters))

  Object.keys(filters).forEach(key => {
    if (filters[key] === null) {
      delete filters[key];
    } else if (typeof(filters[key]) === 'object') {
      if(filters[key].length === 1 && !['course_ids'].includes(key)){
        filters[key] = filters[key].join()
      }
    }
  });
  
  let data = {
    page: page ? page : 1,
    perPage: limit ? limit : 10,
    search: filters,
    sort: {}
  };

  if(sorter?.column) {
    data.sort[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
  }

  try {
  const response = yield call(SuperFetch.post, 'getAssessments', { 'page': data.page, ...data.search, ...data.sort })
  if(response?.success) {
    yield put({
      type: actions.UPDATE_ITEM,
      items: response.data,
      count: response.total_count,
      currentPage: { pagination : { current: data.page } },
      loading: false,
      filterParams
    });
  } else if (response?.success === false) {
    notification('error', response.message);
  }
  } catch (error) {
    console.log(error)
  }
}
export function* updateItemSaga({ item }) {
  let id = false
  if(item.id === 'new') {
    delete item.id
  } else {
    id = item.id === 'new' ? false : item.id
  }
  try {
  const response = yield call(SuperFetch.post, 'saveAssessment', item)
  if(response?.success) {
    if (!id) { response.data.is_newly_saved = true }
    try {
      yield put({
        type: actions.UPDATE_ITEM,
        item: response.data,
        count: id ? undefined : '+1'
      });
      notification('success', response.message);
      yield put({
        type: actions.SELECT_CURRENT_ITEM,
        id: response.data.id
      });
    } catch (error) {
      console.log(error)
    }
  } else if (response?.success === false) {
    if (response?.data?.length) {
      response.data.forEach((e) => {
        if(e.field && e.message) notification('error', `${e.message}: ${e.field}`);
      })
    } else {
      notification('error', response.message);
    }
  }
  } catch (error) {
    console.log(error)
  }
}
export function* loadItem({ id }) {
  if (id !== 'new') {
    try {
    const response = yield call(SuperFetch.post, `getAssessments`, { id })
    if(response?.success) {
      yield put({
        type: actions.UPDATE_ITEM,
        item: response.data[0],
      });
    } else if (response?.success === false) {
      notification('error', response.message);
    }
    } catch (error) {
      console.log(error)
    }
  } else {
    yield put({
      type: actions.SELECT_CURRENT_ITEM_UPDATE,
      id
    });
  }
}
export function* deleteItems({ newItems, deleteItems, count }) {
  const requestsArray = []
  try {
  deleteItems.forEach(e => {
    requestsArray.push(
      call(SuperFetch.post, `deleteAssessment`, { id:e })
    )
  })
  const responseArray =yield all(requestsArray)
  let deletedItems = responseArray.filter(e => e.success === true).length
  let unDeletedItems = responseArray.filter(e => e.success === false).length

  if(deletedItems && unDeletedItems === 0) {
    notification('success', `${deletedItems} Assessment${deletedItems === 1 ? '' : 's'} Deleted`);
    yield put({
      type: actions.UPDATE_ITEM,
      items: newItems
    });
  } else if (unDeletedItems) {
    notification('error', `${unDeletedItems > 1 ? unDeletedItems : ''} Assessment${unDeletedItems === 1 ? '' : 's'} linked could not be deleted`);
    if(deletedItems) {
      notification('error', `Please refresh`);
    }
  }
  } catch (error) {
    console.log(error)
  }
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_ITEM, getItem),
    yield takeEvery(actions.UPDATE_ITEM_SAGA, updateItemSaga),
    yield takeEvery(actions.SELECT_CURRENT_ITEM, loadItem),
    yield takeEvery(actions.DELETE_ITEM_SAGA, deleteItems),
  ]);
}
