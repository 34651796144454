import defaultTheme from './default';
import customTheme from './custom';

const themes = {
  defaultTheme,
  customTheme,
};

export const themeConfig = {
  topbar: 'theme4',
  sidebar: 'theme5',
  layout: 'defaultTheme',
  theme: 'defaultTheme',
};
export default themes;
