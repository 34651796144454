import clone from 'clone';
import { newItem } from './config';
import actions from './actions';

const initState = {
  items: [],
  loading: true,
  initialItems: false,
  filterParams: {},
  currentItem: {},
  editableItem: {},
  isNewItem: false,
  enableEditView: false,
  count: 0,
  rates: {},
  currentPage: { pagination: { current: 1 }}
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_ITEM: {
      const currentItem = action.item
        ? action.item
        : state.currentItem;
      const items = action?.items ? 
          action.item?.id ? action.items.map((e) => {
            if(e.id === action.item.id) {
              return action.item
            } else {
              return e
            }
          }) : action.items :
          action.item?.id ? state.items.map((e) => {
            if(e.id === action.item.id) {
              return action.item
            } else {
              return e
            }
          }) : state.items
      if (action.count === '+1') {
        items.unshift(action.item)
      }
      const rates = {};
      items.forEach((item) => {
        rates[item.symbol] = item.rate_with_usd;
      });
      return {
        ...state,
        loading: action.loading,
        items,
        rates,
        filterParams: action.filterParams || state.filterParams,
        count: action.count === '+1' ? state.count + 1 : (action.count || state.count),
        currentPage: action.currentPage || state.currentPage,
        currentItem: clone(currentItem),
        initialItems: true,
        isNewItem: false,
        enableEditView: false,
      };
    }
    case actions.SELECT_CURRENT_ITEM_UPDATE: {
      const isNewItem = action.id === 'new';
      const currentItem = isNewItem
        ? {
            id: action.id,
            ...newItem,
          }
        : (action.item || state.item);
      const enableEditView = isNewItem;
      return {
        ...state,
        currentItem,
        isNewItem,
        enableEditView,
        editableItem: clone(currentItem),
      };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableItem: clone(state.currentItem),
      };
    case actions.UPDATE_EDIT_ITEM:
      return {
        ...state,
        editableItem: { ...state.editableItem, ...clone(action.item)},
      };
    default:
      return state;
  }
}
