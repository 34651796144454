import { all, takeEvery, put, call } from 'redux-saga/effects';
import SuperFetch from '../../library/helpers/superFetch';
import notification from '@iso/components/Notification';
import actions from './actions';

export function* loadSettings() {
  const response = yield call(SuperFetch.post, 'getSettings', { 'page': 1 })

  let settingsData = {};
  if(response?.data && response.data.length) {
    response.data.forEach(item => {
      if(!settingsData[item.section]) {
        settingsData[item.section] = {
          section: item.section,
          title: item.title || 'Section Title',
          fields: [],
        }
      }
      settingsData[item.section].fields.push({
        id: item.id,
        section: item.section,
        path: item.path,
        type: item.type,
        options: item.options ? JSON.parse(item.options.replaceAll(`'`,`"`)) : [],
        defaultValue: item.value,
        label: item.label,
        placeholder: item.placeholder,
        comment: item.comment
      });
    });
  }
  const keys = Object.keys(settingsData);
  keys.sort();
  const sortedObj = {};
  keys.forEach(key => {
    settingsData[key].fields = settingsData[key].fields.sort((a, b) => a.path.localeCompare(b.path));
    sortedObj[key] = settingsData[key]
  });
  
  yield put({
    type: actions.LOAD_ITEMS_SUCCESS,
    items: Object.values(settingsData)
  });
}
export function* updateSettings({ params: { data }}) {
  try {
    const saveRequests = yield all(data.map(e => SuperFetch.post('saveSettings', e)))
    let allSuccess = true;
    saveRequests.forEach(e => {
      if(!e.success) {
        allSuccess = false;
      }
    })
    if(!allSuccess) {
      console.log(saveRequests)
      throw (new Error())
    }
    notification('success', 'Settings successfully saved')
  } catch (e) {
    notification('error', 'Some error occurred! Please try again later')
  }
  yield put({
    type: actions.LOAD_ITEMS
  });
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LOAD_ITEMS, loadSettings),
    yield takeEvery(actions.UPDATE_ITEM_SAGA, updateSettings),
  ]);
}
