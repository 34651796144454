const localDataName = 'mateContents';
const table = 'contents';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const newItem = {
  title: '',
  url: '',
  source: null,
  date_expiry: null,
  tags: null,
  reference: '',
  tenancy: null,
  content_source_type: null,
  file_path: '',
  body: '',
  user_id: userID,
  client_id: clientID
}

export {
  table,
  localDataName,
  newItem
};
